import { t } from 'i18next';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import {
  Button,
  Chip,
  Divider,
  Grid,
  Group,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowBack } from '@tabler/icons-react';

import {
  selectCosts,
  selectExtras,
  selectHoliday,
  selectPartecipants,
  setCurrentStep,
  setExtras,
} from '@slices/checkout.slice';

import CheckoutSidebar from '@components/CheckoutSidebar';

export default function Extras() {
  // ==========================================================================
  // General
  // ==========================================================================
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setCurrentStep(3));
  }, []);

  // ==========================================================================
  // State
  // ==========================================================================
  const holiday = useSelector(selectHoliday);
  const costs = useSelector(selectCosts);
  const partecipants = useSelector(selectPartecipants);
  const extras = useSelector(selectExtras);

  // ==========================================================================
  // Form
  // ==========================================================================
  const extrasForm = useForm({
    mode: 'uncontrolled',
    initialValues: {
      extras: structuredClone(extras),
    },
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = (values: typeof extrasForm.values) => {
    dispatch(setExtras(values.extras));

    navigate('/checkout/ensurances');
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const areAllExtrasAssigned = useMemo(() => {
    if (
      Object.keys(extras).length === 0 ||
      Object.values(extras).reduce((acc, e) => acc + e.quantity, 0) === 0
    ) {
      return true;
    }

    for (const extra of Object.values(extrasForm.getValues().extras)) {
      if (extra.quantity !== extra.partecipantsIds.length) {
        return false;
      }
    }

    return true;
  }, [extrasForm.getValues(), extras, partecipants]);

  return (
    <form onSubmit={extrasForm.onSubmit(onSubmit)} style={{ height: '100%' }}>
      <Stack h="100%">
        <Grid flex={1} gutter="xl">
          <Grid.Col span={{ md: 8 }}>
            <Button
              component={Link}
              to="/checkout/rooms"
              leftSection={<IconArrowBack />}
              variant="outline"
              size="xs"
              mb="md"
              hiddenFrom="md"
            >
              {t('checkout.stepper.extras.back')}
            </Button>

            <Title order={3} mb="lg">
              {t('checkout.stepper.extras.data.title')}
            </Title>

            {Object.keys(extras).length === 0 ||
            Object.values(extras).reduce((acc, e) => acc + e.quantity, 0) ===
              0 ? (
              <Text>{t('checkout.stepper.extras.data.not_found')}</Text>
            ) : (
              <Stack>
                {Object.entries(extrasForm.getValues().extras).map(
                  ([extra, info]) => (
                    <div key={extra}>
                      <Divider
                        label={
                          holiday?.options.find((o) => o.id === extra)?.name
                        }
                        labelPosition="left"
                        fw="bold"
                        styles={{
                          label: {
                            fontSize: '1.1rem',
                            color: 'var(--mantine-color-black)',
                          },
                        }}
                      />

                      <Text size="sm" mt="xs">
                        {t('checkout.stepper.extras.data.label.first')}{' '}
                        {info.quantity}{' '}
                        {info.quantity > 1
                          ? t('checkout.stepper.extras.data.label.second')
                          : t('checkout.stepper.extras.data.label.third')}
                      </Text>

                      <Group mt="xs" gap="xs">
                        {partecipants.map((partecipant) => (
                          <Chip
                            key={extra + partecipant.key}
                            checked={info.partecipantsIds.includes(
                              partecipant.key,
                            )}
                            onChange={(checked) => {
                              if (checked) {
                                extrasForm.insertListItem(
                                  `extras.${extra}.partecipantsIds`,
                                  partecipant.key,
                                );
                              } else {
                                extrasForm.removeListItem(
                                  `extras.${extra}.partecipantsIds`,
                                  info.partecipantsIds.indexOf(partecipant.key),
                                );
                              }
                            }}
                            disabled={
                              !info.partecipantsIds.includes(partecipant.key) &&
                              info.partecipantsIds.length >= info.quantity //||
                              // !!extrasForm
                              //   .getValues()
                              //   .extras.find(
                              //     (r) =>
                              //       r.key !== extra.key &&
                              //       r.partecipantsIds.includes(partecipant.key),
                              //   )
                            }
                          >
                            {partecipant.firstName} {partecipant.lastName}
                          </Chip>
                        ))}
                      </Group>
                    </div>
                  ),
                )}
              </Stack>
            )}
          </Grid.Col>

          <Grid.Col span={{ md: 4 }}>
            <CheckoutSidebar
              costs={costs}
              submitDisabled={!areAllExtrasAssigned}
              submitDisabledMessage={t('checkout.sidebar.extras')}
            />
          </Grid.Col>
        </Grid>
      </Stack>
    </form>
  );
}
