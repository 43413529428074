import { useMemo } from 'react';

import { Select, SelectProps } from '@mantine/core';
import { capitalize } from '@utils/strings';

import { useGetNationsQuery } from '@api/locations.api';

export default function NationSelector(props: SelectProps) {
  const { data = [], isLoading } = useGetNationsQuery(undefined, {
    skip: props.disabled,
  });

  // Data is initialized to value, if set, to fix initial form values
  const fixedData = useMemo(() => {
    if (props.defaultValue && data.length === 0) {
      return [{ nome: props.defaultValue }];
    }

    return data;
  }, [data, props.defaultValue]);

  const nations = useMemo(() => {
    const nationsSet = new Set(
      fixedData.map((nation) => capitalize(nation.nome)),
    );

    return Array.from(nationsSet)
      .toSorted((a, b) => (a > b ? 1 : -1))
      .map((nation) => ({
        value: nation,
        label: nation,
      }));
  }, [fixedData]);

  return (
    <Select
      label="Nazione"
      placeholder="Seleziona nazione"
      data={nations}
      searchable
      {...props}
      disabled={isLoading || props.disabled}
    />
  );
}
