import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Group,
  Loader,
  LoadingOverlay,
  Stack,
  Table,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { closeModal, openModal } from '@mantine/modals';
import {
  IconAlertCircle,
  IconAlertTriangle,
  IconArrowBack,
} from '@tabler/icons-react';
import { mapRoomTypeName } from '@utils/mappers';
import { priceToDecimal } from '@utils/price';

import { CheckoutPartecipant } from '@interfaces/checkout.interface';

import {
  useCreateBookingMutation,
  usePayBookingMutation,
} from '@api/bookings.api';
import { useGetCurrentCustomerQuery } from '@api/customers.api';
import { isApiError } from '@api/index';

import {
  selectCosts,
  selectEnsurances,
  selectExtras,
  selectHoliday,
  selectPartecipants,
  selectRooms,
  setCurrentStep,
} from '@slices/checkout.slice';

import useAuth from '@hooks/useAuth';

import CheckoutSidebar from '@components/CheckoutSidebar';
import LoginRegisterPage from '@components/LoginRegisterPage';

export default function Summary() {
  // ==========================================================================
  // General
  // ==========================================================================
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentStep(5));
  }, []);

  // ==========================================================================
  // State
  // ==========================================================================
  const [couponCode, setCouponCode] = useState('');
  const [notes, setNotes] = useState('');

  const partecipants = useSelector(selectPartecipants);
  const rooms = useSelector(selectRooms);
  const holidayVariation = useSelector(selectHoliday)!;
  const extras = useSelector(selectExtras);
  const costs = useSelector(selectCosts);
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: customer } = useGetCurrentCustomerQuery(undefined, {
    skip: !isAuthenticated,
  });
  const [createBooking] = useCreateBookingMutation();
  const [payBooking] = usePayBookingMutation();

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleRegisterClick = () => {
    openModal({
      modalId: 'register',
      title: 'Registrazione',
      size: 'xl',
      children: (
        <LoginRegisterPage onLoginCompleted={() => closeModal('register')} />
      ),
    });
  };

  const handleConfirm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      const bookingId = await createBooking({
        clientBaseUrl: window.location.origin,
        holidayId: holidayVariation.holiday.id,
        holidayVariationId: holidayVariation.id,
        customerId: customer!.user.id,
        couponCode: couponCode.trim() !== '' ? couponCode : undefined,
        notes: notes.trim() !== '' ? notes : undefined,
        rooms: rooms.map((r) => {
          // Remove shared with from typeId
          const [t, v, s] = r.typeId!.split('_');

          return {
            id: r.room!.id,
            typeId: `${t}_${v}_${s}`,
            partecipantsIds: r.partecipantsIds,
          };
        }),
        partecipants: partecipants.map((p) => ({
          partecipantId: p.key,
          firstName: p.firstName!,
          lastName: p.lastName!,
          gender: p.gender,
          email: p.email,
          height: p.height,
          birthDate: p.birthDate,
          birthCity: p.birthCity,
          country: p.country,
          province: p.province,
          city: p.city,
          zip: p.zip,
          address: p.address,
          allergies: p.notes,
        })),
        options: Object.entries(extras).map(([id, info]) => ({
          id,
          partecipantsIds: info.partecipantsIds,
        })),
      }).unwrap();

      const { checkoutUrl } = await payBooking(bookingId).unwrap();

      location.href = checkoutUrl;
    } catch (e) {
      setIsLoading(false);

      if (isApiError(e)) {
        openModal({
          title: 'Errore',
          children: <Text c="red">{e.data.message}</Text>,
        });
      } else {
        openModal({
          title: 'Errore',
          children: (
            <Text>
              Si è verificato un errore durante la creazione della prenotazione.
              Riprova più tardi.
            </Text>
          ),
        });
      }

      console.error(e);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form onSubmit={handleConfirm}>
      <LoadingOverlay
        visible={isLoading}
        loaderProps={{
          children: (
            <Stack align="center" justify="center">
              <Loader />
              <Text fw="bold" size="lg">
                Stiamo verificando la tua prenotazione. Non chiudere o
                ricaricare la pagina.
              </Text>
            </Stack>
          ),
        }}
      />
      <Stack h="100%">
        <Grid flex={1} gutter="xl">
          <Grid.Col span={{ md: 8 }}>
            {!isAuthenticated ? (
              <>
                <Alert color="red" icon={<IconAlertCircle />}>
                  {t('checkout.sidebar.summary.message')}
                </Alert>
                <Button onClick={handleRegisterClick} mt="lg">
                  REGISTRATI
                </Button>
              </>
            ) : (
              <Stack>
                <Button
                  component={Link}
                  to="/checkout/ensurances"
                  leftSection={<IconArrowBack />}
                  variant="outline"
                  size="xs"
                  mb="md"
                  hiddenFrom="md"
                >
                  {t('checkout.stepper.summary.back')}
                </Button>

                <Title order={3}>{t('checkout.stepper.summary.title')}</Title>

                <Divider
                  label={t('checkout.stepper.summary.data.divider.first')}
                  labelPosition="left"
                  fw="bold"
                  styles={{
                    label: {
                      fontSize: '1.1rem',
                      color: 'var(--mantine-color-black)',
                    },
                  }}
                />

                {partecipants.map((partecipant, index) => (
                  <div key={partecipant.key}>
                    <Text key={partecipant.key} fw="bold" mb="xs">
                      {partecipant.firstName} {partecipant.lastName}
                      {index === 0
                        ? ' ' + t('checkout.stepper.summary.data.label.first')
                        : ''}
                    </Text>
                    <PartecipantInfo partecipant={partecipant} />
                  </div>
                ))}

                <Alert icon={<IconAlertTriangle />}>
                  {t('checkout.stepper.summary.data.alert')}
                </Alert>

                <Divider
                  label={t('checkout.stepper.summary.data.divider.second')}
                  labelPosition="left"
                  fw="bold"
                  styles={{
                    label: {
                      fontSize: '1.1rem',
                      color: 'var(--mantine-color-black)',
                    },
                  }}
                />

                <Text size="sm">
                  {t('checkout.stepper.summary.data.label.second')}
                </Text>
                <Group>
                  <TextInput
                    placeholder={t(
                      'checkout.stepper.summary.data.label.second',
                    )}
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                    w="20rem"
                  />
                  <Button variant="outline">
                    {t('checkout.stepper.summary.data.label.btn')}
                  </Button>
                </Group>

                <Divider
                  label="Note"
                  labelPosition="left"
                  fw="bold"
                  styles={{
                    label: {
                      fontSize: '1.1rem',
                      color: 'var(--mantine-color-black)',
                    },
                  }}
                />

                <Textarea
                  placeholder="Note prenotazione"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </Stack>
            )}
          </Grid.Col>

          <Grid.Col span={{ md: 4 }}>
            <CheckoutSidebar
              costs={costs}
              submitLabel={t('checkout.sidebar.summary.label')}
              submitDisabled={!isAuthenticated}
              submitDisabledMessage={t('checkout.sidebar.summary.message')}
            />
          </Grid.Col>
        </Grid>
      </Stack>
    </form>
  );
}

function PartecipantInfo({
  partecipant,
}: {
  partecipant: CheckoutPartecipant;
}) {
  const { t } = useTranslation();
  const holiday = useSelector(selectHoliday)!;
  const partecipants = useSelector(selectPartecipants);
  const rooms = useSelector(selectRooms);
  const extras = useSelector(selectExtras);
  const ensurances = useSelector(selectEnsurances);
  const costs = useSelector(selectCosts);

  // TODO: spostare selector in padre. verificare performance con profiling

  const partecipantCosts = useMemo(
    () => ({
      holidayCost: costs.holidayCost / partecipants.length,
      extrasCosts: Object.entries(extras)
        .filter(([, info]) => info.partecipantsIds.includes(partecipant.key))
        .reduce(
          (acc, [extra]) =>
            acc + holiday.options.find((o) => o.id === extra)!.price,
          0,
        ),
      ensurancesCosts: ensurances.ensurance1.includes(partecipant.key) ? 50 : 0,
    }),
    [costs, partecipants],
  );

  return (
    <>
      <Grid gutter="xs" c="#444">
        <Grid.Col span={6}>
          <Text size="sm">
            <Text span fw="bold">
              {t('checkout.stepper.summary.data.info.label.tax_code')}:
            </Text>{' '}
            {partecipant.taxNumber || '-'}
          </Text>
          <Text size="sm">
            <Text span fw="bold">
              {t('checkout.stepper.summary.data.info.label.gender')}:
            </Text>{' '}
            {partecipant.gender === 'female' ? 'Femmina' : 'Maschio'}
          </Text>
          <Text size="sm">
            <Text span fw="bold">
              {t('checkout.stepper.summary.data.info.label.date_birth')}:
            </Text>{' '}
            {partecipant.birthDate
              ? new Date(partecipant.birthDate).toLocaleDateString()
              : '-'}
          </Text>
          <Text size="sm">
            <Text span fw="bold">
              {t('checkout.stepper.summary.data.info.label.city_birth')}:
            </Text>{' '}
            {partecipant.birthCity || '-'}
          </Text>
          <Text size="sm">
            <Text span fw="bold">
              {t('checkout.stepper.summary.data.info.label.height')}:
            </Text>{' '}
            {partecipant.height ? `${partecipant.height} cm` : '-'}
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text size="sm">
            <Text span fw="bold">
              {t('checkout.stepper.summary.data.info.label.country')}:
            </Text>{' '}
            {partecipant.country || '-'}
          </Text>
          <Text size="sm">
            <Text span fw="bold">
              {t('checkout.stepper.summary.data.info.label.provincie')}:
            </Text>{' '}
            {partecipant.province || '-'}
          </Text>
          <Text size="sm">
            <Text span fw="bold">
              {t('checkout.stepper.summary.data.info.label.city')}:
            </Text>{' '}
            {partecipant.city || '-'}
          </Text>
          <Text size="sm">
            <Text span fw="bold">
              {t('checkout.stepper.summary.data.info.label.zip_code')}:
            </Text>{' '}
            {partecipant.zip || '-'}
          </Text>
          <Text size="sm">
            <Text span fw="bold">
              {t('checkout.stepper.summary.data.info.label.address')}:
            </Text>{' '}
            {partecipant.address || '-'}
          </Text>
        </Grid.Col>
        {partecipant.notes && (
          <Grid.Col span={12}>
            <Text span size="sm" fw="bold">
              {t('checkout.stepper.summary.data.info.label.notes')}:
            </Text>{' '}
            <Text size="sm">{partecipant.notes}</Text>
          </Grid.Col>
        )}
      </Grid>

      <Box mt="md" c="#444">
        <Text size="sm" fw="bold" span>
          {t('checkout.stepper.summary.data.info.label.room')}:{' '}
        </Text>
        <Text size="sm" span>
          {mapRoomTypeName(
            rooms.find((r) => r.partecipantsIds.includes(partecipant.key))!
              .typeId!,
          )}
        </Text>
      </Box>

      <Table
        mt="md"
        w="min-content"
        cellPadding={0}
        cellSpacing={0}
        withRowBorders={false}
        styles={{ td: { padding: 0 } }}
      >
        <Table.Tbody>
          <Table.Tr>
            <Table.Td fs="italic" style={{ whiteSpace: 'nowrap' }}>
              {t('checkout.stepper.summary.data.info.label.vacation_cost')}
            </Table.Td>
            <Table.Td style={{ whiteSpace: 'nowrap', paddingLeft: '1rem' }}>
              € {priceToDecimal(partecipantCosts.holidayCost)}
            </Table.Td>
          </Table.Tr>
          {partecipantCosts.extrasCosts > 0 && (
            <Table.Tr>
              <Table.Td fs="italic" style={{ whiteSpace: 'nowrap' }}>
                {t('checkout.stepper.summary.data.info.label.extras')}
              </Table.Td>
              <Table.Td style={{ whiteSpace: 'nowrap', paddingLeft: '1rem' }}>
                € {priceToDecimal(partecipantCosts.extrasCosts)}
              </Table.Td>
            </Table.Tr>
          )}
          {partecipantCosts.ensurancesCosts > 0 && (
            <Table.Tr>
              <Table.Td fs="italic" style={{ whiteSpace: 'nowrap' }}>
                {t('checkout.stepper.summary.data.info.label.insurance')}
              </Table.Td>
              <Table.Td style={{ whiteSpace: 'nowrap', paddingLeft: '1rem' }}>
                € {priceToDecimal(partecipantCosts.ensurancesCosts)}
              </Table.Td>
            </Table.Tr>
          )}
          <Table.Tr>
            <Table.Td fs="italic" style={{ whiteSpace: 'nowrap' }}>
              {t('checkout.stepper.summary.data.info.label.total')}
            </Table.Td>
            <Table.Td style={{ whiteSpace: 'nowrap', paddingLeft: '1rem' }}>
              €{' '}
              {priceToDecimal(
                partecipantCosts.holidayCost +
                  partecipantCosts.extrasCosts +
                  partecipantCosts.ensurancesCosts,
              )}
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>

      <Divider my="md" />
    </>
  );
}
