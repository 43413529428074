import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';

import socket from '@lib/socket';
import {
  Anchor,
  Box,
  Button,
  Center,
  Container,
  Group,
  Loader,
  Menu,
  Paper,
  SegmentedControl,
  Space,
  Stack,
  Stepper,
  Text,
  Title,
} from '@mantine/core';
import { closeModal, openConfirmModal, openModal } from '@mantine/modals';
import { IconLogin, IconLogout, IconUserCircle } from '@tabler/icons-react';
import { formatDate } from '@utils/date';

import { useGetHolidayForCheckoutQuery } from '@api/holidaysVariations.api';

import {
  decrementSessionRemainingTime,
  selectCurrentStep,
  selectHoliday,
  setCosts,
  setExtras,
  setHoliday,
  setPartecipants,
  setRooms,
  setSessionRemainingTime,
} from '@slices/checkout.slice';

import useAuth from '@hooks/useAuth';

import LoginForm from '@components/LoginForm';

import classes from './Checkout.module.css';

// TODO: handle all loading states and test with low bandwidth

export default function Checkout() {
  // ==========================================================================
  // General
  // ==========================================================================
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const { isAuthenticated, logout } = useAuth();

  // ==========================================================================
  // State
  // ==========================================================================
  const currentStep = useSelector(selectCurrentStep);
  const selectedHoliday = useSelector(selectHoliday);

  useEffect(() => {
    // Start timing interval
    const interval = setInterval(() => {
      dispatch(decrementSessionRemainingTime());
    }, 1000);

    const onTimeUpdate = (time: number) => {
      dispatch(setSessionRemainingTime(time));
    };

    socket.on('checkout_session_time_update', onTimeUpdate);

    const onSessionExpired = () => {
      openConfirmModal({
        title: t('modals.checkout.session_expired.title'),
        children: t('modals.checkout.session_expired.description'),
        cancelProps: { display: 'none' },
        closeButtonProps: { display: 'none' },
        closeOnClickOutside: false,
        labels: {
          confirm: t('modals.checkout.session_expired.labels.confirm'),
          cancel: t('modals.checkout.session_expired.labels.cancel'),
        },
        onConfirm: () => {
          dispatch(setPartecipants([]));
          dispatch(setRooms([]));
          dispatch(setExtras({}));
          dispatch(
            setCosts({
              holidayCost: 0,
              roomsCost: 0,
              extrasCost: 0,
              ensurancesCost: 0,
            }),
          );

          navigate('/checkout');
        },
      });
    };

    socket.on('checkout_session_expired', onSessionExpired);

    return () => {
      clearInterval(interval);
      socket.off('checkout_session_time_update', onTimeUpdate);
      socket.off('checkout_session_expired', onSessionExpired);
    };
  }, [dispatch]);

  // ==========================================================================
  // Api
  // ==========================================================================
  const {
    data: holiday,
    isLoading,
    error,
  } = useGetHolidayForCheckoutQuery(
    {
      holidayId: searchParams.get('h') || '',
      variationId: searchParams.get('v') || '',
    },
    { skip: selectedHoliday !== null },
  );

  useEffect(() => {
    dispatch(setHoliday(holiday || null));
  }, [holiday]);

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onLoginClick = () => {
    openModal({
      modalId: 'login',
      title: t('modals.checkout.login.title'),
      children: <LoginForm onLoginCompleted={() => closeModal('login')} />,
    });
  };

  const onQuotationStepClick = () => {
    openConfirmModal({
      title: t('modals.checkout.booking_cancel.title'),
      children: t('modals.checkout.booking_cancel.description'),
      labels: {
        cancel: t('modals.checkout.booking_cancel.labels.cancel'),
        confirm: t('modals.checkout.booking_cancel.labels.confirm'),
      },
      confirmProps: { color: 'red' },
      onConfirm: () => {
        dispatch(setPartecipants([]));
        dispatch(setRooms([]));
        dispatch(setExtras({}));
        dispatch(
          setCosts({
            holidayCost: 0,
            roomsCost: 0,
            extrasCost: 0,
            ensurancesCost: 0,
          }),
        );

        navigate('/checkout');

        socket.emit('checkout_session_finish');

        // TODO: refetch holiday
      },
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Container size="85rem" py="xl" h="100dvh">
      {isLoading ? (
        <Center h="100%">
          <Loader />
        </Center>
      ) : error || !selectedHoliday ? (
        <Center h="100%">
          <Text size="lg" fw="bold">
            {t('checkout.error.title')}
          </Text>
          <Button component="a" href="https://jonas.it" mt="xl">
            {t('checkout.error.button')}
          </Button>
        </Center>
      ) : (
        <Stack gap={0}>
          <Group hiddenFrom="sm" justify="space-between" mb="lg">
            <Anchor href="https://jonas.it" target="_blank">
              <img
                className={classes.logo}
                src="https://staging.jonas.it/wp-content/webp-express/webp-images/uploads/2024/11/logo-jonas_blu.png.webp"
              />
            </Anchor>
            <Group>
              <SegmentedControl
                data={['IT', 'EN']}
                size="xs"
                value={i18n.language.toUpperCase()}
                onChange={(val: string) => {
                  i18n.changeLanguage(val.toLowerCase());
                }}
              />
              <Menu position="bottom-end">
                <Menu.Target>
                  <Group wrap="nowrap" style={{ cursor: 'pointer' }}>
                    <IconUserCircle
                      size="2rem"
                      color="var(--mantine-color-gray-8)"
                    />
                  </Group>
                </Menu.Target>
                <Menu.Dropdown>
                  {isAuthenticated ? (
                    <Menu.Item onClick={logout} c="red">
                      <Group>
                        <IconLogout size="1.5rem" />
                        <Text size="sm">Logout</Text>
                      </Group>
                    </Menu.Item>
                  ) : (
                    <Menu.Item onClick={onLoginClick}>
                      <Group>
                        <IconLogin size="1.5rem" />
                        <Text size="sm">Login</Text>
                      </Group>
                    </Menu.Item>
                  )}
                </Menu.Dropdown>
              </Menu>
            </Group>
          </Group>
          <Group justify="space-between" wrap="nowrap">
            <Group gap="xl">
              <Anchor href="https://jonas.it" target="_blank" visibleFrom="sm">
                <img
                  className={classes.logo}
                  src="https://staging.jonas.it/wp-content/webp-express/webp-images/uploads/2024/11/logo-jonas_blu.png.webp"
                />
              </Anchor>
              <Box>
                <Title className={classes.title}>
                  {selectedHoliday.holiday.name}
                </Title>
                <Title className={classes.subtitle} order={3} c="dimmed">
                  {formatDate(
                    new Date(selectedHoliday.startDate),
                    i18n.language,
                  )}
                  -{' '}
                  {formatDate(new Date(selectedHoliday.endDate), i18n.language)}
                </Title>
              </Box>
            </Group>
            <Group visibleFrom="sm">
              <SegmentedControl
                data={['IT', 'EN']}
                size="xs"
                value={i18n.language.toUpperCase()}
                onChange={(val: string) => {
                  i18n.changeLanguage(val.toLowerCase());
                }}
              />
              <Menu position="bottom-end">
                <Menu.Target>
                  <Group wrap="nowrap" style={{ cursor: 'pointer' }}>
                    <IconUserCircle
                      size="2rem"
                      color="var(--mantine-color-gray-8)"
                    />
                  </Group>
                </Menu.Target>
                <Menu.Dropdown>
                  {isAuthenticated ? (
                    <Menu.Item onClick={logout} c="red">
                      <Group>
                        <IconLogout size="1.5rem" />
                        <Text size="sm">Logout</Text>
                      </Group>
                    </Menu.Item>
                  ) : (
                    <Menu.Item onClick={onLoginClick}>
                      <Group>
                        <IconLogin size="1.5rem" />
                        <Text size="sm">Login</Text>
                      </Group>
                    </Menu.Item>
                  )}
                </Menu.Dropdown>
              </Menu>
            </Group>
          </Group>

          <Stepper
            active={currentStep}
            onStepClick={(i) => {
              switch (i) {
                case 0:
                  onQuotationStepClick();
                  break;
                case 1:
                  navigate('/checkout/partecipants');
                  break;
                case 2:
                  navigate('/checkout/rooms');
                  break;
                case 3:
                  navigate('/checkout/extras');
                  break;
                case 4:
                  navigate('/checkout/insurance');
                  break;
                case 5:
                  navigate('/checkout/summary');
                  break;
              }
            }}
            allowNextStepsSelect={false}
            mt="xl"
            flex={1}
            classNames={{
              root: classes.stepperRoot,
              content: classes.stepperContent,
              steps: classes.stepperSteps,
            }}
          >
            <Stepper.Step
              label={t('checkout.stepper.quotation.title')}
            ></Stepper.Step>
            <Stepper.Step
              label={t('checkout.stepper.partecipants.title')}
            ></Stepper.Step>
            <Stepper.Step
              label={t('checkout.stepper.rooms.title')}
            ></Stepper.Step>
            <Stepper.Step
              label={t('checkout.stepper.extras.title')}
            ></Stepper.Step>
            <Stepper.Step
              label={t('checkout.stepper.insurance.title')}
            ></Stepper.Step>
            <Stepper.Step
              label={t('checkout.stepper.summary.title')}
            ></Stepper.Step>
          </Stepper>

          <Paper withBorder className={classes.paper} mt="xl">
            <Outlet />
          </Paper>
        </Stack>
      )}

      <Space h="xl" />
    </Container>
  );
}
