import { useMemo } from 'react';

import { Select, SelectProps } from '@mantine/core';

import { useGetProvincesQuery } from '@api/locations.api';

export default function ProvinceSelector(props: SelectProps) {
  const { data = [], isLoading } = useGetProvincesQuery(undefined, {
    skip: props.disabled,
  });

  // Data is initialized to value, if set, to fix initial form values
  const fixedData = useMemo(() => {
    if (props.defaultValue && data.length === 0) {
      return [{ sigla: props.defaultValue, nome: props.defaultValue }];
    }

    return data;
  }, [data, props.defaultValue]);

  return (
    <Select
      label="Provincia"
      placeholder="Seleziona provincia"
      data={fixedData
        .toSorted((a, b) => (a.nome > b.nome ? 1 : -1))
        .map((province) => ({
          value: province.sigla,
          label: province.nome,
        }))}
      searchable
      {...props}
      disabled={isLoading || props.disabled}
    />
  );
}
