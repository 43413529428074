import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import socket from '@lib/socket';
import {
  ActionIcon,
  Alert,
  Button,
  Divider,
  Grid,
  Group,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import {
  IconAlertCircle,
  IconInfoCircle,
  IconMinus,
  IconPlus,
} from '@tabler/icons-react';
import {
  compareRoomTypes,
  mapRoomTypeInfo,
  mapRoomTypeName,
} from '@utils/mappers';
import { priceToDecimal } from '@utils/price';

import {
  CheckoutExtras,
  CheckoutPartecipant,
  CheckoutRoom,
} from '@interfaces/checkout.interface';
import {
  HolidayVariationRoom,
  HolidayVariationRoomTypes,
} from '@interfaces/holidays.interface';

import {
  selectHoliday,
  setCosts,
  setCurrentStep,
  setExtras,
  setPartecipants,
  setRooms,
  setSessionRemainingTime,
} from '@slices/checkout.slice';

import CheckoutSidebar from '../../components/CheckoutSidebar';
import classes from './Quotation.module.css';

export default function Quotation() {
  // ==========================================================================
  // General
  // ==========================================================================
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setCurrentStep(0));
  }, []);

  // ==========================================================================
  // State
  // ==========================================================================
  const selectedHoliday = useSelector(selectHoliday);

  // ==========================================================================
  // Form
  // ==========================================================================
  const quotationForm = useForm({
    mode: 'uncontrolled',
    initialValues: {
      partecipants: {
        maleCount: 0,
        femaleCount: 0,
      },
      rooms: [] as CheckoutRoom[],
      extras: {} as CheckoutExtras,
    },
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = (values: typeof quotationForm.values) => {
    const partecipants: CheckoutPartecipant[] = [];

    for (let i = 0; i < values.partecipants.femaleCount; i++) {
      partecipants.push({
        key: randomId(),
        gender: 'female',
        email: '',
      });
    }

    for (let i = 0; i < values.partecipants.maleCount; i++) {
      partecipants.push({
        key: randomId(),
        gender: 'male',
        email: '',
      });
    }

    dispatch(setPartecipants(partecipants));
    dispatch(setRooms(values.rooms));
    dispatch(setExtras(values.extras));
    dispatch(
      setCosts({
        holidayCost,
        roomsCost,
        extrasCost,
        ensurancesCost: 0,
      }),
    );

    dispatch(setSessionRemainingTime(900));

    socket.emit('checkout_session_start', {
      selectedRoomsIds: values.rooms.map((r) => r.room?.id),
    });

    navigate('/checkout/partecipants');
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const initialAvailableRoomTypes = useMemo(() => {
    const roomTypes: Partial<Record<string, HolidayVariationRoomTypes>> = {};

    if (selectedHoliday) {
      for (const room of selectedHoliday.rooms) {
        for (const roomType of room.types) {
          let type = roomType.typeId;

          if (roomType.typeId.split('_')[2] === 'S') {
            if (room.sharedWith === 'male') {
              type += '_M';
            } else if (room.sharedWith === 'female') {
              type += '_F';
            }
          }

          if (!roomTypes[type]) {
            roomTypes[type] = roomType;
          }
        }
      }
    }

    return roomTypes;
  }, [selectedHoliday]);

  // Group rooms by type
  const mappedAvailableRooms = useMemo(() => {
    const mappedRooms: Partial<Record<string, HolidayVariationRoom[]>> = {};

    if (selectedHoliday) {
      for (const room of selectedHoliday.rooms) {
        if (
          quotationForm.getValues().rooms.find((r) => r.room?.id === room.id)
        ) {
          // Skip room if selected
          continue;
        }

        for (const roomType of room.types) {
          let type = roomType.typeId;

          if (roomType.typeId.split('_')[2] === 'S') {
            if (room.sharedWith === 'male') {
              type += '_M';
            } else if (room.sharedWith === 'female') {
              type += '_F';
            }
          }

          if (!mappedRooms[type]) {
            mappedRooms[type] = [];
          }

          mappedRooms[type]!.push(room);

          mappedRooms[type]!.sort((a, b) =>
            a.types.length > b.types.length ? 1 : -1,
          );
        }
      }
    }

    return mappedRooms;
  }, [selectedHoliday, quotationForm.getValues()]);

  const partecipantsCount = useMemo(
    () =>
      quotationForm.getValues().partecipants.femaleCount +
      quotationForm.getValues().partecipants.maleCount,
    [quotationForm.getValues().partecipants],
  );

  const holidayCost = useMemo(
    () => (selectedHoliday?.price || 0) * partecipantsCount,
    [selectedHoliday?.price, partecipantsCount],
  );

  const roomsCost = useMemo(
    () =>
      quotationForm
        .getValues()
        .rooms.reduce(
          (acc, r) =>
            acc +
            r.room!.types.find((t) => compareRoomTypes(t.typeId, r.typeId!))!
              .price,
          0,
        ),
    [quotationForm.getValues()],
  );

  const extrasCost = useMemo(
    () =>
      Object.entries(quotationForm.getValues().extras).reduce(
        (acc, [extra, { quantity }]) =>
          acc +
          (selectedHoliday?.options.find((o) => o.id === extra)!.price || 0) *
            quantity,
        0,
      ),
    [selectedHoliday?.options, quotationForm.getValues()],
  );

  const areRoomsValids = useMemo(() => {
    let remainingFemalePartecipants =
      quotationForm.getValues().partecipants.femaleCount;
    let remainingMalePartecipants =
      quotationForm.getValues().partecipants.maleCount;

    let femaleOnlySpots = 0;
    let maleOnlySpots = 0;
    let unisexSpots = 0;

    for (const room of quotationForm.getValues().rooms) {
      if (room.room?.sharedWith === 'female') {
        femaleOnlySpots++;
      } else if (room.room?.sharedWith === 'male') {
        maleOnlySpots++;
      } else {
        unisexSpots += room.room!.types.find(
          (t) => t.typeId === room.typeId,
        )!.spotsPerBooking;
      }
    }

    for (let i = 0; i < femaleOnlySpots; i++) {
      remainingFemalePartecipants--;
      if (remainingFemalePartecipants < 0) {
        return false;
      }
    }

    for (let i = 0; i < maleOnlySpots; i++) {
      remainingMalePartecipants--;
      if (remainingMalePartecipants < 0) {
        return false;
      }
    }

    for (let i = 0; i < unisexSpots; i++) {
      if (remainingMalePartecipants === 0) {
        remainingFemalePartecipants--;
        if (remainingFemalePartecipants < 0) {
          return false;
        }
      } else {
        remainingMalePartecipants--;
      }
    }

    return remainingFemalePartecipants + remainingMalePartecipants === 0;
  }, [quotationForm.getValues()]);

  const areExtrasValids = useMemo(() => {
    for (const [, { quantity }] of Object.entries(
      quotationForm.getValues().extras,
    )) {
      if (quantity > partecipantsCount) {
        return false;
      }
    }

    return true;
  }, [quotationForm.getValues(), partecipantsCount]);

  return (
    <form onSubmit={quotationForm.onSubmit(onSubmit)}>
      <Stack h="100%">
        <Grid flex={1} gutter="xl">
          <Grid.Col span={{ md: 8 }}>
            <Divider
              label={t('checkout.stepper.quotation.data.participants.title')}
              labelPosition="left"
              classNames={{ label: classes.dividerLabel }}
            />

            <Alert fw="bold" mt="md">
              <Group justify="space-between">
                <Group gap="xs">
                  <ThemeIcon variant="transparent">
                    <IconAlertCircle />
                  </ThemeIcon>
                  {t('checkout.stepper.quotation.data.participants.info.title')}
                </Group>
                <Button variant="outline">
                  {t(
                    'checkout.stepper.quotation.data.participants.info.button',
                  )}
                </Button>
              </Group>
            </Alert>

            <Grid gutter="xl" mt="xl">
              <Grid.Col span={{ md: 6 }}>
                <Group wrap="nowrap" justify="space-between">
                  <Text className={classes.itemText}>
                    {t('checkout.stepper.quotation.data.participants.woman')}
                  </Text>
                  <CustomNumberInput
                    value={quotationForm.getValues().partecipants.femaleCount}
                    onChange={(value) =>
                      quotationForm.setFieldValue(
                        'partecipants.femaleCount',
                        value,
                      )
                    }
                  />
                </Group>
              </Grid.Col>

              <Grid.Col span={{ md: 6 }}>
                <Group wrap="nowrap" justify="space-between">
                  <Text className={classes.itemText}>
                    {t('checkout.stepper.quotation.data.participants.man')}
                  </Text>
                  <CustomNumberInput
                    value={quotationForm.getValues().partecipants.maleCount}
                    onChange={(value) =>
                      quotationForm.setFieldValue(
                        'partecipants.maleCount',
                        value,
                      )
                    }
                  />
                </Group>
              </Grid.Col>
            </Grid>

            <Divider
              label={t('checkout.stepper.quotation.data.rooms.title')}
              labelPosition="left"
              mt="xl"
              classNames={{ label: classes.dividerLabel }}
            />

            <Grid gutter="xl" mt="xl">
              {Object.entries(initialAvailableRoomTypes).map(
                ([typeId, info]) => (
                  <Grid.Col key={typeId} span={{ md: 6 }}>
                    <Group wrap="nowrap" justify="space-between">
                      <Group wrap="nowrap" gap="xs">
                        <Text className={classes.itemText}>
                          {mapRoomTypeName(typeId)}
                          {info!.price !== 0 &&
                            ` (€ ${priceToDecimal(info!.price)})`}
                        </Text>
                        {mapRoomTypeInfo(typeId) && (
                          <Tooltip
                            label={mapRoomTypeInfo(typeId)}
                            multiline
                            maw="15rem"
                            ta="center"
                          >
                            <IconInfoCircle />
                          </Tooltip>
                        )}
                      </Group>

                      <CustomNumberInput
                        value={
                          quotationForm
                            .getValues()
                            .rooms.filter((r) => r.typeId === typeId).length
                        }
                        onChange={(value) => {
                          if (
                            value <
                            quotationForm
                              .getValues()
                              .rooms.filter((r) => r.typeId === typeId).length
                          ) {
                            quotationForm.removeListItem(
                              'rooms',
                              quotationForm
                                .getValues()
                                .rooms.findLastIndex(
                                  (r) => r.typeId === typeId,
                                ),
                            );
                          } else {
                            quotationForm.insertListItem('rooms', {
                              key: randomId(),
                              room: mappedAvailableRooms[typeId]![0],
                              typeId,
                              partecipantsIds: [],
                            });
                          }
                        }}
                        minDisabled={
                          !quotationForm
                            .getValues()
                            .rooms.find((r) => r.typeId === typeId)
                        }
                        maxDisabled={!mappedAvailableRooms[typeId]}
                      />
                    </Group>
                  </Grid.Col>
                ),
              )}
            </Grid>

            <Divider
              label={t('checkout.stepper.quotation.data.extras.title')}
              labelPosition="left"
              mt="xl"
              classNames={{ label: classes.dividerLabel }}
            />

            <Grid gutter="xl" mt="xl">
              {selectedHoliday!.options.map((option) => (
                <Grid.Col key={option.id} span={12}>
                  <Group wrap="nowrap" justify="space-between">
                    <Text className={classes.itemText}>
                      {option.name} (€{' '}
                      {priceToDecimal(option.price) +
                        (option.type === 'perPartecipant'
                          ? '/partecipante'
                          : '')}
                      )
                    </Text>
                    <CustomNumberInput
                      value={
                        quotationForm.getValues().extras[option.id]?.quantity ||
                        0
                      }
                      onChange={(value) =>
                        quotationForm.setFieldValue(`extras.${option.id}`, {
                          quantity: value,
                          partecipantsIds: [],
                        })
                      }
                      maxDisabled={
                        (quotationForm.getValues().extras[option.id]
                          ?.quantity || 0) === option.quantity ||
                        (quotationForm.getValues().extras[option.id]
                          ?.quantity || 0) >= partecipantsCount
                      }
                    />
                  </Group>
                </Grid.Col>
              ))}
            </Grid>
          </Grid.Col>

          <Grid.Col span={{ md: 4 }}>
            <CheckoutSidebar
              costs={{
                holidayCost,
                roomsCost,
                extrasCost,
                ensurancesCost: 0,
              }}
              showRemainingTime={false}
              submitDisabled={
                partecipantsCount === 0 || !areRoomsValids || !areExtrasValids
              }
              submitDisabledMessage={
                partecipantsCount === 0
                  ? t('checkout.sidebar.quotation.participants')
                  : !areRoomsValids
                    ? t('checkout.sidebar.quotation.rooms')
                    : !areExtrasValids
                      ? t('checkout.sidebar.quotation.extras')
                      : undefined
              }
            />
          </Grid.Col>
        </Grid>
      </Stack>
    </form>
  );
}

function CustomNumberInput({
  value,
  onChange,
  maxDisabled,
  minDisabled,
}: {
  value: number;
  onChange: (value: number) => void;
  maxDisabled?: boolean;
  minDisabled?: boolean;
}) {
  return (
    <Group wrap="nowrap">
      <ActionIcon
        onClick={() => onChange(value - 1)}
        disabled={minDisabled !== undefined ? minDisabled : value === 0}
      >
        <IconMinus />
      </ActionIcon>
      <Text ff="Roboto" fw={500}>
        {value}
      </Text>
      <ActionIcon onClick={() => onChange(value + 1)} disabled={maxDisabled}>
        <IconPlus />
      </ActionIcon>
    </Group>
  );
}
