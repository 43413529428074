import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import env from '@config/env';

export interface Nazione {
  sigla: string;
  nome: string;
}

export interface Provincia {
  sigla: string;
  nome: string;
}

export interface Comune {
  codice: string;
  nome: string;
  cap: string;
}

export interface Cap {
  cap: string;
}

const apiComuni = createApi({
  reducerPath: 'apiComuni',
  baseQuery: fetchBaseQuery({
    baseUrl: env.APICOMUNI_URL,
    headers: {
      'x-api-key': env.APICOMUNI_API_KEY,
    },
  }),
  endpoints: (builder) => ({
    getNations: builder.query<Nazione[], void>({
      query: () => ({
        url: '/nazioni',
        cache: 'no-cache',
      }),
    }),
    getProvinces: builder.query<Provincia[], void>({
      query: () => ({
        url: '/province',
        cache: 'no-cache',
      }),
    }),
    getCities: builder.query<Comune[], string>({
      query: (provinceCode) => ({
        url: '/comuni',
        cache: 'no-cache',
        params: { siglaProvincia: provinceCode },
      }),
    }),
    getZips: builder.query<Cap[], string>({
      query: (city) => ({
        url: '/cap',
        cache: 'no-cache',
        params: { nomeCitta: city },
      }),
    }),
  }),
});

export default apiComuni;

export const {
  useGetProvincesQuery,
  useGetCitiesQuery,
  useGetNationsQuery,
  useGetZipsQuery,
} = apiComuni;
