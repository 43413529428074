import { createBrowserRouter } from 'react-router-dom';

import CheckoutLayout from '@components/checkoutLayout/CheckoutLayout';

import BookingSuccess from './BookingSuccess';
import Ensurances from './Ensurances';
import Extras from './Extras';
import NotFound from './NotFound';
import Partecipants from './partecipants/Partecipants';
import Quotation from './quotation/Quotation';
import Rooms from './Rooms';
import Summary from './Summary';

const router = createBrowserRouter([
  {
    path: '/checkout',
    element: <CheckoutLayout />,
    children: [
      {
        index: true,
        element: <Quotation />,
      },
      {
        path: '/checkout/partecipants',
        element: <Partecipants />,
      },
      {
        path: '/checkout/rooms',
        element: <Rooms />,
      },
      {
        path: '/checkout/extras',
        element: <Extras />,
      },
      {
        path: '/checkout/ensurances',
        element: <Ensurances />,
      },
      {
        path: '/checkout/summary',
        element: <Summary />,
      },
    ],
  },
  {
    path: '/booking-success',
    element: <BookingSuccess />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

export default router;
