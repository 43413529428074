import parser from 'any-date-parser';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Button,
  Checkbox,
  Divider,
  Grid,
  NumberInput,
  PasswordInput,
  SegmentedControl,
  Select,
  Text,
  TextInput,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { handleSubmitError } from '@utils/forms';

import { Gender } from '@interfaces/common.interface';
import { CustomerType } from '@interfaces/customer.interface';

import { useCreateCustomerMutation } from '@api/customers.api';

import { selectPartecipants } from '@slices/checkout.slice';

import useFormEnhanced from '@hooks/useFormEnhanced';
import useGRecaptcha from '@hooks/useGRecaptcha';

import {
  CitySelector,
  NationSelector,
  ProvinceSelector,
  ZipSelector,
} from '@components/common/locations';

import classes from './RegisterForm.module.css';

interface RegisterFormProps {
  onRegistrationCompleted: () => void;
}

export default function RegisterForm({
  onRegistrationCompleted,
}: RegisterFormProps) {
  // ==========================================================================
  // General
  // ==========================================================================
  const { getRecaptchaToken } = useGRecaptcha();

  // ==========================================================================
  // State
  // ==========================================================================
  const [isDomicileDifferent, setIsDomicileDifferent] = useState(false);

  const partecipants = useSelector(selectPartecipants);
  const holderPartecipant = useMemo(() => {
    const holder = partecipants[0];

    return holder;
  }, [partecipants]);

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    email: '', // required
    emailConfirm: '',
    password: '',
    passwordConfirm: '', // required
    type: 'private' as CustomerType,
    firstName: holderPartecipant?.firstName || '',
    lastName: holderPartecipant?.lastName || '',
    companyName: '',
    vatTaxNumber: holderPartecipant?.taxNumber || '',
    pec: '',
    sdi: '',
    gender: (holderPartecipant?.gender || '') as Gender,
    address: holderPartecipant?.address || '',
    city: holderPartecipant?.city || '',
    zip: holderPartecipant?.zip || '',
    country: holderPartecipant?.country || '',
    province: holderPartecipant?.province || '',
    birthDate: holderPartecipant?.birthDate
      ? new Date(holderPartecipant.birthDate)
      : undefined,
    birthCity: holderPartecipant?.birthCity || '',
    height: holderPartecipant?.height || 0,
    nationality: '',
    phone: '',
    domicileCountry: '',
    domicileProvince: '',
    domicileCity: '',
    domicileZip: '',
    domicileAddress: '',
    privacy: false,
    newsLetter: false, // if true add user in MagNews service
  };

  const form = useFormEnhanced({
    initialValues,
    validate: {
      emailConfirm: (value, values) =>
        value !== values.email ? 'Le email non corrispondono' : null,
      passwordConfirm: (value, values) =>
        value !== values.password ? 'Le password non corrispondono' : null,
    },
    normalizeFields: {
      capitalizeFields: [
        'firstName',
        'lastName',
        'companyName',
        'birthCity',
        'country',
        'province',
        'city',
        'address',
        'nationality',
        'domicileCountry',
        'domicileProvince',
        'domicileCity',
        'domicileAddress',
      ],
      lowercaseFields: ['email', 'pec'],
      uppercaseFields: ['vatTaxNumber', 'sdi'],
    },
  });

  // ==========================================================================
  // Api
  // ==========================================================================
  const [createCustomer, { isLoading }] = useCreateCustomerMutation();

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = async (values: typeof initialValues) => {
    try {
      const recaptchaToken = await getRecaptchaToken('SignUp');

      const mappedValues = {
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        type: values.type,
        companyName:
          values.companyName.trim() !== '' ? values.companyName : undefined,
        vatTaxNumber:
          values.vatTaxNumber.trim() !== '' ? values.vatTaxNumber : undefined,
        pec: values.pec.trim() !== '' ? values.pec : undefined,
        sdi: values.sdi.trim() !== '' ? values.sdi : undefined,
        gender: values.gender,
        birthDate: values.birthDate?.toISOString() || undefined,
        birthCity: values.birthCity,
        height: values.height,
        phone: values.phone,
        country: values.country,
        province: values.province,
        city: values.city,
        zip: values.zip,
        address: values.address,
        nationality:
          values.nationality.trim() !== '' ? values.nationality : undefined,
        domicileCountry:
          values.type === 'private'
            ? values.domicileCountry.trim() !== ''
              ? values.domicileCountry
              : values.country
            : undefined,
        domicileProvince:
          values.type === 'private'
            ? values.domicileProvince.trim() !== ''
              ? values.domicileProvince
              : values.province
            : undefined,
        domicileCity:
          values.type === 'private'
            ? values.domicileCity.trim() !== ''
              ? values.domicileCity
              : values.province
            : undefined,
        domicileZip:
          values.type === 'private'
            ? values.domicileZip.trim() !== ''
              ? values.domicileZip
              : values.zip
            : undefined,
        domicileAddress:
          values.type === 'private'
            ? values.domicileAddress.trim() !== ''
              ? values.domicileAddress
              : values.address
            : undefined,
      };

      await createCustomer({ body: mappedValues, recaptchaToken }).unwrap();

      onRegistrationCompleted();
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <Divider
        label="Informazioni account"
        labelPosition="left"
        mt="xl"
        mb="sm"
        classNames={{ label: classes.dividerLabel }}
      />

      <Grid>
        <Grid.Col span={{ md: 6 }}>
          <TextInput
            label="Email"
            placeholder="email@example.com"
            required
            {...form.getInputProps('email')}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 6 }}>
          <TextInput
            label="Ripeti email"
            placeholder="email@example.com"
            required
            {...form.getInputProps('emailConfirm')}
          />
        </Grid.Col>

        <Grid.Col span={{ md: 6 }}>
          <PasswordInput
            label="Password"
            placeholder="La tua password"
            required
            {...form.getInputProps('password')}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 6 }}>
          <PasswordInput
            label="Ripeti password"
            placeholder="La tua password"
            required
            {...form.getInputProps('passwordConfirm')}
          />
        </Grid.Col>
      </Grid>

      <Divider
        label="Informazioni base"
        labelPosition="left"
        mt="xl"
        mb="sm"
        classNames={{ label: classes.dividerLabel }}
      />

      <SegmentedControl
        mb="md"
        data={[
          { label: 'Privato', value: 'private' },
          { label: 'Azienda', value: 'company' },
        ]}
        {...form.getInputProps('type')}
      />

      <Grid>
        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label="Nome"
            placeholder="Il tuo nome"
            required
            {...form.getInputProps('firstName')}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label="Cognome"
            placeholder="Il tuo cognome"
            required
            {...form.getInputProps('lastName')}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label={
              form.values.type === 'company' ? 'Partita IVA' : 'Codice fiscale'
            }
            placeholder={
              form.values.type === 'company' ? 'Partita IVA' : 'Codice fiscale'
            }
            required={form.values.country === 'Italia'}
            {...form.getInputProps('vatTaxNumber')}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          {form.values.type === 'company' ? (
            <TextInput
              label="Ragione sociale"
              placeholder="Ragione sociale dell'azienda"
              required
              {...form.getInputProps('companyName')}
            />
          ) : (
            <Select
              label="Sesso"
              placeholder="Sesso"
              required
              data={[
                { label: 'Maschio', value: 'male' },
                { label: 'Femmina', value: 'female' },
              ]}
              {...form.getInputProps('gender')}
            />
          )}
        </Grid.Col>
        {form.values.type === 'company' && (
          <>
            <Grid.Col span={{ md: 4 }}>
              <TextInput
                label="PEC"
                placeholder="PEC"
                {...form.getInputProps('pec')}
              />
            </Grid.Col>
            <Grid.Col span={{ md: 4 }}>
              <TextInput
                label="SDI"
                placeholder="SDI"
                {...form.getInputProps('sdi')}
              />
            </Grid.Col>
          </>
        )}

        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label="Telefono"
            placeholder="+39 123456789"
            required
            {...form.getInputProps('phone')}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          {form.values.type === 'private' && (
            <NumberInput
              label="Altezza (cm)"
              placeholder="Altezza (cm)"
              required
              min={50}
              {...form.getInputProps('height')}
            />
          )}
        </Grid.Col>

        {form.values.type === 'private' && (
          <>
            <Grid.Col span={{ md: 4 }}>
              <DateInput
                label="Data di nascita"
                placeholder="Data di nascita"
                required
                valueFormat="DD/MM/YYYY"
                dateParser={(value) => parser.fromString(value, 'it')}
                {...form.getInputProps('birthDate')}
              />
            </Grid.Col>
            <Grid.Col span={{ md: 4 }}>
              <TextInput
                label="Città di nascita"
                placeholder="Città di nascita"
                required
                {...form.getInputProps('birthCity')}
              />
            </Grid.Col>
          </>
        )}
        {form.values.type === 'private' && (
          <Grid.Col span={{ md: 4 }}>
            <TextInput
              label="Nazionalità"
              placeholder="Nazionalità"
              required
              {...form.getInputProps('nationality')}
            />
          </Grid.Col>
        )}
      </Grid>

      <Divider
        label="Residenza"
        labelPosition="left"
        mt="xl"
        mb="sm"
        classNames={{ label: classes.dividerLabel }}
      />

      <Grid>
        <Grid.Col span={{ md: 4 }}>
          <NationSelector
            placeholder="Nazione residenza"
            required
            {...form.getInputProps('country')}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          {form.values.country === 'Italia' ? (
            <ProvinceSelector
              required
              placeholder="Provincia residenza"
              {...form.getInputProps('province')}
            />
          ) : (
            <TextInput
              label="Città"
              placeholder="Città residenza"
              required
              {...form.getInputProps('city')}
            />
          )}
        </Grid.Col>

        {form.values.country === 'Italia' && (
          <>
            <Grid.Col span={{ md: 4 }}>
              <CitySelector
                required
                placeholder="Città residenza"
                province={form.values.province}
                {...form.getInputProps('city')}
                disabled={form.values.province === ''}
              />
            </Grid.Col>
            <Grid.Col span={{ md: 4 }}>
              <ZipSelector
                required
                placeholder="CAP residenza"
                city={form.values.city}
                {...form.getInputProps('zip')}
                disabled={form.values.city === ''}
              />
            </Grid.Col>
          </>
        )}
        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label="Indirizzo"
            placeholder="Indirizzo residenza"
            required
            {...form.getInputProps('address')}
          />
        </Grid.Col>
      </Grid>

      {form.values.type === 'private' && (
        <>
          <Divider
            label="Domicilio"
            labelPosition="left"
            mt="xl"
            mb="sm"
            classNames={{ label: classes.dividerLabel }}
          />

          <Checkbox
            w="max-content"
            mb="lg"
            label="Domicilio diverso da residenza"
            checked={isDomicileDifferent}
            onChange={(e) => setIsDomicileDifferent(e.currentTarget.checked)}
          />

          {isDomicileDifferent && (
            <Grid>
              <Grid.Col span={{ md: 4 }}>
                <NationSelector
                  placeholder="Nazione residenza"
                  required
                  {...form.getInputProps('domicileCountry')}
                />
              </Grid.Col>
              <Grid.Col span={{ md: 4 }}>
                {form.values.domicileCountry === 'Italia' ? (
                  <ProvinceSelector
                    required
                    placeholder="Provincia domicilio"
                    {...form.getInputProps('domicileProvince')}
                  />
                ) : (
                  <TextInput
                    label="Città"
                    placeholder="Città domicilio"
                    required
                    {...form.getInputProps('domicileCity')}
                  />
                )}
              </Grid.Col>

              {form.values.domicileCountry === 'Italia' && (
                <>
                  <Grid.Col span={{ md: 4 }}>
                    <CitySelector
                      required
                      placeholder="Città domicilio"
                      province={form.values.domicileProvince}
                      {...form.getInputProps('domicileCity')}
                      disabled={form.values.domicileProvince === ''}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ md: 4 }}>
                    <ZipSelector
                      required
                      placeholder="CAP domicilio"
                      city={form.values.domicileCity}
                      {...form.getInputProps('domicileZip')}
                      disabled={form.values.domicileCity === ''}
                    />
                  </Grid.Col>
                </>
              )}
              <Grid.Col span={{ md: 4 }}>
                <TextInput
                  label="Indirizzo"
                  placeholder="Indirizzo domicilio"
                  required
                  {...form.getInputProps('domicileAddress')}
                />
              </Grid.Col>
            </Grid>
          )}
        </>
      )}

      {/* TODO: privacy and newsletter */}

      {form.errors.general && (
        <Text c="red" size="sm" mt="xl">
          {form.errors.general}
        </Text>
      )}
      <Button
        type="submit"
        mt="xl"
        style={{ display: 'block' }}
        loading={isLoading}
      >
        Registrati
      </Button>
    </form>
  );
}
