import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import {
  Alert,
  Button,
  Chip,
  Divider,
  Grid,
  Group,
  Stack,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowBack, IconCheck } from '@tabler/icons-react';

import {
  selectCosts,
  selectEnsurances,
  selectPartecipants,
  setCosts,
  setCurrentStep,
  setEnsurances,
} from '@slices/checkout.slice';

import CheckoutSidebar from '@components/CheckoutSidebar';

export default function Ensurances() {
  // ==========================================================================
  // General
  // ==========================================================================
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setCurrentStep(4));
  }, []);

  // ==========================================================================
  // State
  // ==========================================================================
  const costs = useSelector(selectCosts);
  const partecipants = useSelector(selectPartecipants);
  const ensurances = useSelector(selectEnsurances);
  const { t } = useTranslation();
  // ==========================================================================
  // Form
  // ==========================================================================
  const ensurancesForm = useForm({
    mode: 'uncontrolled',
    initialValues: {
      ensurances: structuredClone(ensurances),
    },
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = (values: typeof ensurancesForm.values) => {
    dispatch(setEnsurances(values.ensurances));

    dispatch(
      setCosts({
        holidayCost: costs.holidayCost,
        roomsCost: costs.roomsCost,
        extrasCost: costs.extrasCost,
        ensurancesCost,
      }),
    );

    navigate('/checkout/summary');
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const ensurancesCost = useMemo(
    () => ensurancesForm.getValues().ensurances.ensurance1.length * 5000,
    [ensurancesForm.getValues()],
  );

  return (
    <form
      onSubmit={ensurancesForm.onSubmit(onSubmit)}
      style={{ height: '100%' }}
    >
      <Stack h="100%">
        <Grid flex={1} gutter="xl">
          <Grid.Col span={{ md: 8 }}>
            <Button
              component={Link}
              to="/checkout/extras"
              leftSection={<IconArrowBack />}
              variant="outline"
              size="xs"
              mb="md"
              hiddenFrom="md"
            >
              {t('checkout.stepper.insurance.back')}
            </Button>

            <Title order={3} mb="lg">
              {t('checkout.stepper.insurance.data.title')}
            </Title>

            <Alert color="green" icon={<IconCheck />}>
              {t('checkout.stepper.insurance.data.alert')}
            </Alert>

            <Divider
              mt="md"
              label={t('checkout.stepper.insurance.data.title')}
              labelPosition="left"
              fw="bold"
              styles={{
                label: {
                  fontSize: '1.1rem',
                  color: 'var(--mantine-color-black)',
                },
              }}
            />

            <Text>{t('checkout.stepper.insurance.data.label.first')}</Text>

            <Text size="sm" mt="xs">
              {t('checkout.stepper.insurance.data.label.second')}
            </Text>

            <Group mt="xs" gap="xs">
              {partecipants.map((partecipant) => (
                <Tooltip
                  key={'ensurance1' + partecipant.key}
                  label={t('checkout.stepper.insurance.data.label.third')}
                  styles={{
                    tooltip: {
                      display: partecipants.find(
                        (p) => p.key === partecipant.key,
                      )?.birthDate
                        ? 'none'
                        : undefined,
                    },
                  }}
                >
                  <div>
                    <Chip
                      checked={ensurancesForm
                        .getValues()
                        .ensurances.ensurance1.includes(partecipant.key)}
                      onChange={(checked) => {
                        if (checked) {
                          ensurancesForm.insertListItem(
                            'ensurances.ensurance1',
                            partecipant.key,
                          );
                        } else {
                          ensurancesForm.removeListItem(
                            'ensurances.ensurance1',
                            ensurancesForm
                              .getValues()
                              .ensurances.ensurance1.indexOf(partecipant.key),
                          );
                        }
                      }}
                      disabled={
                        !partecipants.find((p) => p.key === partecipant.key)
                          ?.birthDate
                      }
                    >
                      {partecipant.firstName} {partecipant.lastName}
                    </Chip>
                  </div>
                </Tooltip>
              ))}
            </Group>
          </Grid.Col>

          <Grid.Col span={{ md: 4 }}>
            <CheckoutSidebar costs={{ ...costs, ensurancesCost }} />
          </Grid.Col>
        </Grid>
      </Stack>
    </form>
  );
}
