import { useMemo } from 'react';

import { Select, SelectProps } from '@mantine/core';

import { useGetZipsQuery } from '@api/locations.api';

export default function ZipSelector(props: { city: string } & SelectProps) {
  const { data = [], isLoading } = useGetZipsQuery(props.city, {
    skip: props.disabled,
  });

  // Data is initialized to value, if set, to fix initial form values
  const fixedData = useMemo(() => {
    if (props.defaultValue && data.length === 0) {
      return [{ cap: props.defaultValue }];
    }

    return data;
  }, [data, props.defaultValue]);

  return (
    <Select
      label="CAP"
      placeholder="Seleziona CAP"
      data={fixedData
        .toSorted((a, b) => (a.cap > b.cap ? 1 : -1))
        .map((city) => ({
          value: city.cap,
          label: city.cap,
        }))}
      searchable
      {...props}
      disabled={isLoading || props.disabled}
    />
  );
}
