export const dateToDateString = (date: Date): string => {
  return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${date.getFullYear()}`;
};

export const dateToHourString = (date: Date): string => {
  return `${date.getHours().toString().padStart(2, '0')}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')}`;
};

export const dateToDateHourString = (date: Date): string => {
  return `${dateToDateString(date)} ${dateToHourString(date)}`;
};

export const secondsToFormattedTime = (totalSeconds: number): string => {
  const minutes = Math.max(Math.floor(totalSeconds / 60), 0);
  const seconds = Math.max(totalSeconds % 60, 0);

  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

export const formatDate = (date: Date, locale: string): string => {
  const dateFormatter = new Intl.DateTimeFormat(locale, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  return dateFormatter.format(date);
};
