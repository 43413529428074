import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Alert, Button, Group, Paper, Stack, Table, Text } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import { formatDate, secondsToFormattedTime } from '@utils/date';
import { priceToDecimal } from '@utils/price';

import { CheckoutCosts } from '@interfaces/checkout.interface';

import {
  selectHoliday,
  selectSessionRemainingTime,
} from '@slices/checkout.slice';

interface CheckoutSidebarProps {
  costs: CheckoutCosts;
  showRemainingTime?: boolean;
  submitLabel?: string;
  submitDisabled?: boolean;
  submitDisabledMessage?: string;
}

export default function CheckoutSidebar({
  costs,
  showRemainingTime = true,
  submitLabel,
  submitDisabled = false,
  submitDisabledMessage = '',
}: CheckoutSidebarProps) {
  const { t, i18n } = useTranslation();
  const holiday = useSelector(selectHoliday)!;
  const sessionRemainingTime = useSelector(selectSessionRemainingTime);

  return (
    <Paper bg="brand" c="white" p="lg" pos="sticky" top="2rem">
      <Stack gap={0}>
        <Text fw="bold" size="xl" ta="center">
          {holiday.holiday.name}
        </Text>
        <Text c="#ddd" ta="center">
          {formatDate(new Date(holiday.startDate), i18n.language)} -{' '}
          {formatDate(new Date(holiday.endDate), i18n.language)}
        </Text>

        <Table
          mt="lg"
          mb="xl"
          withRowBorders={false}
          cellPadding={0}
          cellSpacing={0}
        >
          <Table.Tbody>
            <Table.Tr>
              <Table.Td>
                <Text fs="italic" style={{ whiteSpace: 'nowrap' }}>
                  {t('checkout.stepper.recap.label.holiday')}
                </Text>
              </Table.Td>
              <Table.Td align="right">
                <Text fw="bold" style={{ whiteSpace: 'nowrap' }}>
                  € {priceToDecimal(costs.holidayCost)}
                </Text>
              </Table.Td>
            </Table.Tr>
            {costs.roomsCost !== 0 && (
              <Table.Tr>
                <Table.Td>
                  <Text fs="italic" style={{ whiteSpace: 'nowrap' }}>
                    {t('checkout.stepper.recap.label.rooms')}
                  </Text>
                </Table.Td>
                <Table.Td align="right">
                  <Text fw="bold" style={{ whiteSpace: 'nowrap' }}>
                    € {priceToDecimal(costs.roomsCost)}
                  </Text>
                </Table.Td>
              </Table.Tr>
            )}
            {costs.extrasCost !== 0 && (
              <Table.Tr>
                <Table.Td>
                  <Text fs="italic" style={{ whiteSpace: 'nowrap' }}>
                    {t('checkout.stepper.recap.label.extras')}
                  </Text>
                </Table.Td>
                <Table.Td align="right">
                  <Text fw="bold" style={{ whiteSpace: 'nowrap' }}>
                    € {priceToDecimal(costs.extrasCost)}
                  </Text>
                </Table.Td>
              </Table.Tr>
            )}
            {costs.ensurancesCost !== 0 && (
              <Table.Tr>
                <Table.Td>
                  <Text fs="italic" style={{ whiteSpace: 'nowrap' }}>
                    {t('checkout.stepper.recap.label.insurance')}
                  </Text>
                </Table.Td>
                <Table.Td align="right">
                  <Text fw="bold" style={{ whiteSpace: 'nowrap' }}>
                    € {priceToDecimal(costs.ensurancesCost)}
                  </Text>
                </Table.Td>
              </Table.Tr>
            )}
            <Table.Tr style={{ borderTop: '1px solid #E9ECEF' }}>
              <Table.Td>
                <Text fs="italic">
                  {t('checkout.stepper.recap.label.total')}
                </Text>
              </Table.Td>
              <Table.Td align="right">
                <Text fw="bold" size="xl">
                  €{' '}
                  {priceToDecimal(
                    costs.holidayCost +
                      costs.roomsCost +
                      costs.extrasCost +
                      costs.ensurancesCost,
                  )}
                </Text>
              </Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>

        {showRemainingTime && (
          <Alert variant="white" mb="lg" ta="center">
            <Group justify="center" gap="xs">
              {/* <ThemeIcon variant="transparent">
                      <IconHourglassHigh />
                    </ThemeIcon> */}
              <Text c="#e51c9d" fw="800" size="xl">
                {secondsToFormattedTime(sessionRemainingTime)}
              </Text>
            </Group>
            <Text size="sm">
              {t('checkout.stepper.recap.label.remaining_time')}
            </Text>
          </Alert>
        )}

        {submitDisabled && (
          <Alert
            color="red"
            variant="filled"
            mb="sm"
            icon={<IconAlertCircle />}
          >
            {submitDisabledMessage}
          </Alert>
        )}

        <Button
          variant="white"
          size="lg"
          w="100%"
          type="submit"
          disabled={submitDisabled}
        >
          {submitLabel || t('checkout.stepper.recap.action.button')}
        </Button>
      </Stack>
    </Paper>
  );
}
