import {
  CreateBookingDto,
  PayBookingResponse,
} from '@interfaces/bookings.interface';

import api from './';

export const { useCreateBookingMutation, usePayBookingMutation } =
  api.injectEndpoints({
    endpoints: (build) => ({
      createBooking: build.mutation<number, CreateBookingDto>({
        query: (body) => ({
          url: 'bookings',
          method: 'POST',
          body,
        }),
      }),
      payBooking: build.mutation<PayBookingResponse, number>({
        query: (id) => ({
          url: `bookings/${id}/pay`,
          method: 'POST',
        }),
      }),
    }),
  });
