import { useMemo } from 'react';

import { Select, SelectProps } from '@mantine/core';

import { useGetCitiesQuery } from '@api/locations.api';

export default function CitySelector(
  props: { province: string } & SelectProps,
) {
  const { data = [], isLoading } = useGetCitiesQuery(props.province, {
    skip: props.disabled,
  });

  // Data is initialized to value, if set, to fix initial form values
  const fixedData = useMemo(() => {
    if (props.defaultValue && data.length === 0) {
      return [{ nome: props.defaultValue }];
    }

    return data;
  }, [data, props.defaultValue]);

  return (
    <Select
      label="Città"
      placeholder="Seleziona città"
      data={fixedData
        .toSorted((a, b) => (a.nome > b.nome ? 1 : -1))
        .map((city) => ({
          value: city.nome,
          label: city.nome,
        }))}
      searchable
      {...props}
      disabled={isLoading || props.disabled}
    />
  );
}
