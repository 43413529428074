import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import {
  Button,
  Chip,
  Divider,
  Grid,
  Group,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowBack } from '@tabler/icons-react';
import { compareRoomTypes, mapRoomTypeName } from '@utils/mappers';

import {
  selectCosts,
  selectPartecipants,
  selectRooms,
  setCurrentStep,
  setRooms,
} from '@slices/checkout.slice';

import CheckoutSidebar from '@components/CheckoutSidebar';

export default function Rooms() {
  // ==========================================================================
  // General
  // ==========================================================================
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setCurrentStep(2));
  }, []);

  // ==========================================================================
  // State
  // ==========================================================================
  const costs = useSelector(selectCosts);
  const rooms = useSelector(selectRooms);
  const partecipants = useSelector(selectPartecipants);
  const { t } = useTranslation();
  // ==========================================================================
  // Form
  // ==========================================================================
  const roomsForm = useForm({
    mode: 'uncontrolled',
    initialValues: {
      rooms: structuredClone(rooms),
    },
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = (values: typeof roomsForm.values) => {
    dispatch(setRooms(values.rooms));

    navigate('/checkout/extras');
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const areAllPartecipantsAllocated = useMemo(
    () =>
      roomsForm
        .getValues()
        .rooms.reduce((acc, r) => acc + r.partecipantsIds.length, 0) ===
      partecipants.length,
    [roomsForm.getValues(), partecipants],
  );

  return (
    <form onSubmit={roomsForm.onSubmit(onSubmit)} style={{ height: '100%' }}>
      <Stack h="100%">
        <Grid flex={1} gutter="xl">
          <Grid.Col span={{ md: 8 }}>
            <Button
              component={Link}
              to="/checkout/partecipants"
              leftSection={<IconArrowBack />}
              variant="outline"
              size="xs"
              mb="lg"
              hiddenFrom="md"
            >
              {t('checkout.stepper.rooms.back')}
            </Button>

            <Title order={3} mb="lg">
              {t('checkout.stepper.rooms.data.title')}
            </Title>

            <Stack>
              {roomsForm.getValues().rooms.map((room, index) => (
                <div key={room.key}>
                  <Divider
                    label={
                      t('checkout.stepper.rooms.data.divider') +
                      ` ${mapRoomTypeName(room.typeId!)}`
                    }
                    labelPosition="left"
                    fw="bold"
                    styles={{
                      label: {
                        fontSize: '1.1rem',
                        color: 'var(--mantine-color-black)',
                      },
                    }}
                  />
                  <Text size="sm" mt="xs">
                    {t('checkout.stepper.rooms.data.label.first')}{' '}
                    {
                      room.room?.types.find((t) =>
                        compareRoomTypes(t.typeId, room.typeId!),
                      )?.spotsPerBooking
                    }{' '}
                    {room.room!.types.find((t) =>
                      compareRoomTypes(t.typeId, room.typeId!),
                    )!.spotsPerBooking > 1
                      ? t('checkout.stepper.rooms.data.label.second')
                      : t('checkout.stepper.rooms.data.label.third')}
                  </Text>

                  <Group mt="xs" gap="xs">
                    {partecipants.map((partecipant) => (
                      <Chip
                        key={room.key + partecipant.key}
                        checked={room.partecipantsIds.includes(partecipant.key)}
                        onChange={(checked) => {
                          if (checked) {
                            roomsForm.insertListItem(
                              `rooms.${index}.partecipantsIds`,
                              partecipant.key,
                            );
                          } else {
                            roomsForm.removeListItem(
                              `rooms.${index}.partecipantsIds`,
                              room.partecipantsIds.indexOf(partecipant.key),
                            );
                          }
                        }}
                        disabled={
                          (room.room?.sharedWith === 'male' &&
                            partecipant.gender === 'female') ||
                          (room.room?.sharedWith === 'female' &&
                            partecipant.gender === 'male') ||
                          (!room.partecipantsIds.includes(partecipant.key) &&
                            room.partecipantsIds.length >=
                              room.room!.types.find((t) =>
                                compareRoomTypes(t.typeId, room.typeId!),
                              )!.spotsPerBooking) ||
                          !!roomsForm
                            .getValues()
                            .rooms.find(
                              (r) =>
                                r.key !== room.key &&
                                r.partecipantsIds.includes(partecipant.key),
                            )
                        }
                      >
                        {partecipant.firstName} {partecipant.lastName}
                      </Chip>
                    ))}
                  </Group>
                </div>
              ))}
            </Stack>
          </Grid.Col>

          <Grid.Col span={{ md: 4 }}>
            <CheckoutSidebar
              costs={costs}
              submitDisabled={!areAllPartecipantsAllocated}
              submitDisabledMessage={t('checkout.sidebar.rooms')}
            />
          </Grid.Col>
        </Grid>
      </Stack>
    </form>
  );
}
