export const generateRandomAlphabeticString = (length: number): string => {
  const a = new Array(length)
    .fill(0)
    .map(() => Math.floor(Math.random() * 25) + 65);

  return String.fromCharCode(...a);
};

export const capitalize = (str: string): string => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
