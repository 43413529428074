import parser from 'any-date-parser';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import {
  Alert,
  Anchor,
  Button,
  Checkbox,
  Divider,
  Grid,
  Group,
  NumberInput,
  Select,
  Stack,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm, UseFormReturnType } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import { closeModal, openModal } from '@mantine/modals';
import { Icon24Hours, IconArrowBack, IconUser } from '@tabler/icons-react';

import { Gender } from '@interfaces/customer.interface';

import { useGetCurrentCustomerQuery } from '@api/customers.api';

import {
  selectCosts,
  selectEnsurances,
  selectPartecipants,
  setCurrentStep,
  setPartecipants,
} from '@slices/checkout.slice';

import useAuth from '@hooks/useAuth';

import {
  NationSelector,
  ProvinceSelector,
  ZipSelector,
} from '@components/common/locations';
import CitySelector from '@components/common/locations/CitySelector';
import LoginForm from '@components/LoginForm';

import CheckoutSidebar from '../../components/CheckoutSidebar';
import classes from './Partecipants.module.css';

export default function Partecipants() {
  // ==========================================================================
  // General
  // ==========================================================================
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuthenticated } = useAuth();

  useEffect(() => {
    dispatch(setCurrentStep(1));
  }, []);

  // ==========================================================================
  // State
  // ==========================================================================
  const costs = useSelector(selectCosts);
  const partecipants = useSelector(selectPartecipants);

  const [partecipantsGenders, setPartecipantsGenders] = useState<Gender[]>(
    partecipants.map((p) => p.gender!),
  );

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: customer } = useGetCurrentCustomerQuery(undefined, {
    skip: !isAuthenticated,
  });

  // ==========================================================================
  // Form
  // ==========================================================================
  const partecipantsForm = useForm({
    mode: 'uncontrolled',
    initialValues: {
      partecipants: structuredClone(
        partecipants.map((p) => ({
          ...p,
          // Set gender to undefined if coming from step 0 (aka name are not set)
          gender: p.firstName ? p.gender : undefined,
          birthDate: p.birthDate ? new Date(p.birthDate) : undefined,
        })),
      ),
    },
  });

  useEffect(() => {
    if (customer && customer.type === 'private') {
      partecipantsForm.setFieldValue('partecipants.0', {
        key: randomId(),
        firstName: customer.user.firstName,
        lastName: customer.user.lastName,
        gender: customer.gender,
        email: customer.user.email,
        birthDate: customer.birthDate
          ? new Date(customer.birthDate)
          : undefined,
        taxNumber: customer.vatTaxNumber,
        birthCity: customer.birthCity,
        height: customer.height,
        country: customer.country,
        province: customer.province,
        city: customer.city,
        zip: customer.zip,
        address: customer.address,
      });

      setPartecipantsGenders((prev) => [customer.gender!, ...prev.slice(1)]);
    }
  }, [customer]);

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onLoginClick = () => {
    openModal({
      modalId: 'login',
      title: t('modals.checkout.login.title'),
      children: <LoginForm onLoginCompleted={() => closeModal('login')} />,
    });
  };

  const onSubmit = (values: typeof partecipantsForm.values) => {
    dispatch(
      setPartecipants(
        values.partecipants.map((p) => ({
          ...p,
          gender: p.gender!,
          birthDate: p.birthDate?.toISOString(),
        })),
      ),
    );

    navigate('/checkout/rooms');
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const areSelectedGendersValid = useMemo(() => {
    let availableFemale = partecipants.filter(
      (p) => p.gender === 'female',
    ).length;
    let availableMale = partecipants.filter((p) => p.gender === 'male').length;

    for (const gender of partecipantsGenders) {
      if (gender === undefined) {
        return true;
      } else if (gender === 'female') {
        availableFemale--;
        if (availableFemale < 0) {
          return false;
        }
      } else {
        availableMale--;
        if (availableMale < 0) {
          return false;
        }
      }
    }

    return availableFemale + availableMale === 0;
  }, [partecipants, partecipantsGenders]);

  return (
    <form
      onSubmit={partecipantsForm.onSubmit(onSubmit)}
      style={{ height: '100%' }}
    >
      <Stack h="100%">
        <Grid flex={1} gutter="xl">
          <Grid.Col span={{ md: 8 }}>
            <Button
              component={Link}
              to="/checkout/rooms"
              leftSection={<IconArrowBack />}
              variant="outline"
              size="xs"
              mb="md"
              hiddenFrom="md"
            >
              {t('checkout.stepper.partecipants.back')}
            </Button>

            <Title order={3} mb="lg">
              {t('checkout.stepper.partecipants.data.title')}
            </Title>

            {!isAuthenticated && (
              <Alert mb="lg" icon={<IconUser />}>
                {t('checkout.stepper.partecipants.data.alert.title')}{' '}
                <Anchor
                  size="sm"
                  component="button"
                  type="button"
                  onClick={onLoginClick}
                >
                  {t(
                    'checkout.stepper.partecipants.data.alert.description.label',
                  )}
                </Anchor>{' '}
                {t(
                  'checkout.stepper.partecipants.data.alert.description.description',
                )}
              </Alert>
            )}

            <Stack gap="xl">
              {partecipantsForm
                .getValues()
                .partecipants.map((partecipant, index) => (
                  <PartecipantsInputs
                    key={partecipant.key}
                    index={index}
                    partecipantsForm={partecipantsForm}
                    partecipantKey={partecipant.key}
                    onGenderChange={(gender) => {
                      setPartecipantsGenders((prev) => [
                        ...prev.slice(0, index),
                        gender,
                        ...prev.slice(index + 1),
                      ]);
                    }}
                  />
                ))}
            </Stack>
          </Grid.Col>

          <Grid.Col span={{ md: 4 }}>
            <CheckoutSidebar
              costs={costs}
              submitDisabled={!areSelectedGendersValid}
              submitDisabledMessage={t('checkout.sidebar.partecipants')}
            />
          </Grid.Col>
        </Grid>
      </Stack>
    </form>
  );
}

function PartecipantsInputs({
  index,
  partecipantsForm,
  partecipantKey,
  onGenderChange,
}: {
  index: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  partecipantsForm: UseFormReturnType<any>;
  partecipantKey: string;
  onGenderChange: (gender: Gender) => void;
}) {
  const { i18n, t } = useTranslation();

  const ensurances = useSelector(selectEnsurances);

  const [country, setCountry] = useState(
    partecipantsForm.getValues().partecipants[index].country,
  );
  const [province, setProvince] = useState(
    partecipantsForm.getValues().partecipants[index].province,
  );
  const [city, setCity] = useState(
    partecipantsForm.getValues().partecipants[index].city,
  );

  return (
    <div>
      {index === 1 && (
        <Alert mb="lg" icon={<Icon24Hours />}>
          <Group justify="space-between">
            {t('checkout.stepper.partecipants.data.description')}
          </Group>
        </Alert>
      )}

      <Divider
        label={`Partecipante ${index + 1}${index === 0 ? ' ' + t('checkout.stepper.partecipants.data.divider') : ''}`}
        labelPosition="left"
        fw="bold"
        classNames={{
          label: classes.dividerLabel,
        }}
      />

      <Grid mt="md">
        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label={t('checkout.stepper.partecipants.data.form.name.label')}
            placeholder={t(
              'checkout.stepper.partecipants.data.form.name.placeholder',
            )}
            required
            {...partecipantsForm.getInputProps(
              `partecipants.${index}.firstName`,
            )}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label={t('checkout.stepper.partecipants.data.form.surname.label')}
            placeholder={t(
              'checkout.stepper.partecipants.data.form.surname.placeholder',
            )}
            required
            {...partecipantsForm.getInputProps(
              `partecipants.${index}.lastName`,
            )}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          <Select
            label={t('checkout.stepper.partecipants.data.form.gender.label')}
            placeholder={t(
              'checkout.stepper.partecipants.data.form.gender.placeholder',
            )}
            required
            {...partecipantsForm.getInputProps(`partecipants.${index}.gender`)}
            data={[
              { value: 'male', label: 'Maschio' },
              { value: 'female', label: 'Femmina' },
            ]}
            onChange={(value) => {
              onGenderChange(value as Gender);
              partecipantsForm.setFieldValue(
                `partecipants.${index}.gender`,
                value as Gender,
              );
            }}
          />
        </Grid.Col>

        <Grid.Col
          span={{ md: 4 }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',
          }}
        >
          <TextInput
            label={t('checkout.stepper.partecipants.data.form.email.label')}
            placeholder={t(
              'checkout.stepper.partecipants.data.form.email.placeholder',
            )}
            required
            {...partecipantsForm.getInputProps(`partecipants.${index}.email`)}
          />
        </Grid.Col>
        <Grid.Col
          span={{ md: 4 }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',
          }}
        >
          <TextInput
            label={t('checkout.stepper.partecipants.data.form.tax_code.label')}
            placeholder={t(
              'checkout.stepper.partecipants.data.form.tax_code.placeholder',
            )}
            required={index === 0 && country === 'Italia'}
            {...partecipantsForm.getInputProps(
              `partecipants.${index}.taxNumber`,
            )}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          <DateInput
            label={t(
              'checkout.stepper.partecipants.data.form.date_birth.label',
            )}
            placeholder={t(
              'checkout.stepper.partecipants.data.form.date_birth.placeholder',
            )}
            description={
              index !== 0
                ? t(
                    'checkout.stepper.partecipants.data.form.date_birth.description',
                  )
                : undefined
            }
            required={index === 0}
            {...partecipantsForm.getInputProps(
              `partecipants.${index}.birthDate`,
            )}
            dateParser={(value) => parser.fromString(value, i18n.language)}
            valueFormat="DD/MM/YYYY"
            // TODO: min date
          />
        </Grid.Col>

        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label={t(
              'checkout.stepper.partecipants.data.form.city_birth.label',
            )}
            placeholder={t(
              'checkout.stepper.partecipants.data.form.city_birth.placeholder',
            )}
            required={index === 0}
            {...partecipantsForm.getInputProps(
              `partecipants.${index}.birthCity`,
            )}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          <NumberInput
            label={t('checkout.stepper.partecipants.data.form.height.label')}
            placeholder={t(
              'checkout.stepper.partecipants.data.form.height.placeholder',
            )}
            min={50}
            required={index === 0}
            {...partecipantsForm.getInputProps(`partecipants.${index}.height`)}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          <NationSelector
            required={index === 0}
            {...partecipantsForm.getInputProps(`partecipants.${index}.country`)}
            onChange={(value) => {
              setCountry(value);
              setProvince('');
              setCity('');
              partecipantsForm.setFieldValue(
                `partecipants.${index}.country`,
                value,
              );
              partecipantsForm.setFieldValue(`partecipants.${index}.city`, '');
              partecipantsForm.setFieldValue(
                `partecipants.${index}.province`,
                '',
              );
            }}
          />
        </Grid.Col>

        <Grid.Col span={{ md: 4 }}>
          {country === 'Italia' ? (
            <ProvinceSelector
              required={index === 0}
              {...partecipantsForm.getInputProps(
                `partecipants.${index}.province`,
              )}
              onChange={(value) => {
                setProvince(value);
                setCity('');
                partecipantsForm.setFieldValue(
                  `partecipants.${index}.province`,
                  value,
                );
                partecipantsForm.setFieldValue(
                  `partecipants.${index}.city`,
                  '',
                );
              }}
            />
          ) : (
            <TextInput
              label="Città"
              placeholder="Città"
              required={index === 0}
              {...partecipantsForm.getInputProps(`partecipants.${index}.city`)}
            />
          )}
        </Grid.Col>

        {country === 'Italia' && (
          <>
            <Grid.Col span={{ md: 4 }}>
              <CitySelector
                required={index === 0}
                province={province}
                {...partecipantsForm.getInputProps(
                  `partecipants.${index}.city`,
                )}
                disabled={province === ''}
                onChange={(value) => {
                  setCity(value);
                  partecipantsForm.setFieldValue(
                    `partecipants.${index}.city`,
                    value,
                  );
                }}
              />
            </Grid.Col>
            <Grid.Col span={{ md: 4 }}>
              <ZipSelector
                required={index === 0}
                city={city}
                {...partecipantsForm.getInputProps(`partecipants.${index}.zip`)}
                disabled={city === ''}
              />
            </Grid.Col>
          </>
        )}

        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label={t('checkout.stepper.partecipants.data.form.address.label')}
            placeholder={t(
              'checkout.stepper.partecipants.data.form.address.placeholder',
            )}
            required={index === 0}
            {...partecipantsForm.getInputProps(`partecipants.${index}.address`)}
          />
        </Grid.Col>

        <Grid.Col span={12}>
          <Textarea
            label={t('checkout.stepper.partecipants.data.form.notes.label')}
            placeholder={t(
              'checkout.stepper.partecipants.data.form.notes.placeholder',
            )}
            {...partecipantsForm.getInputProps(`partecipants.${index}.notes`)}
          />
        </Grid.Col>

        {ensurances.ensurance1.includes(partecipantKey) && (
          <Grid.Col span={12}>
            <Checkbox
              label={t('checkout.stepper.partecipants.data.form.terms.label')}
              required
              checked={
                partecipantsForm.getValues().partecipants[index].isItalian
              }
              onChange={(e) =>
                partecipantsForm.setFieldValue(
                  `partecipants.${index}.isItalian`,
                  e.currentTarget.checked,
                )
              }
            />
          </Grid.Col>
        )}
      </Grid>
    </div>
  );
}
