import { useForm, UseFormInput } from '@mantine/form';
import { capitalize } from '@utils/strings';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface UseFormEnhancedProps<Values extends Record<string, any>>
  extends UseFormInput<Values> {
  normalizeFields?: {
    capitalizeFields?: Partial<keyof Values>[];
    uppercaseFields?: Partial<keyof Values>[];
    lowercaseFields?: Partial<keyof Values>[];
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useFormEnhanced = <Values extends Record<string, any>>(
  props: UseFormEnhancedProps<Values>,
) => {
  const form = useForm<Values>({
    ...props,
    enhanceGetInputProps: props.normalizeFields
      ? (payload) => ({
          onBlur: () => {
            const fieldName = payload.field as keyof Values;

            if (props.normalizeFields?.capitalizeFields?.includes(fieldName)) {
              payload.form.setFieldValue(
                payload.field,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                capitalize(payload.inputProps.value.trim()) as any,
              );
            }

            if (props.normalizeFields?.uppercaseFields?.includes(fieldName)) {
              payload.form.setFieldValue(
                payload.field,
                payload.inputProps.value.toUpperCase(),
              );
            }

            if (props.normalizeFields?.lowercaseFields?.includes(fieldName)) {
              payload.form.setFieldValue(
                payload.field,
                payload.inputProps.value.toLowerCase(),
              );
            }
          },
        })
      : undefined,
  });

  return form;
};

export default useFormEnhanced;
